import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Academy, HELP_URL } from '@aps-management/primapp-common';
import { useQuery } from '@tanstack/react-query';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  Chip,
  Paper,
  Grid,
  Button,
  ButtonBase,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import masterClient from '_utils/masterClient';
import { MdOutlineBook } from 'react-icons/md';
/* */
import { Screen } from '_components/core';
import { CalendarDay } from '_components/elements';

/* */
const styles = (theme) => ({
  item: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    padding: theme.spacing.unit * 2,
  },
  chip: {
    display: 'flex',
    borderColor: 'transparent',
    justifyContent: 'flex-start',
  },
  chipIcon: {
    marginLeft: 0,
    marginRight: theme.spacing.unit * -0.5,
  },
  canceled: {
    top: 25,
    right: -80,
    width: 250,
    overflow: 'hidden',
    fontWeight: 'bold',
    lineHeight: '30px',
    textAlign: 'center',
    position: 'absolute',
    transform: 'rotate(40deg)',
    boxShadow: theme.shadows[3],
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.dark,
  },
  inProgress: {
    top: 25,
    right: -80,
    width: 250,
    overflow: 'hidden',
    fontWeight: 'bold',
    lineHeight: '30px',
    textAlign: 'center',
    position: 'absolute',
    transform: 'rotate(40deg)',
    boxShadow: theme.shadows[3],
    color: theme.palette.common.white,
    backgroundColor: '#318445',
  },
  slots: {
    marginLeft: theme.spacing.unit * 1,
    height: 25,
    fontWeight: 'bold',
    borderRadius: '5px',
    backgroundColor: 'rgba(132, 189, 227, 1)',
    cursor: 'pointer',
  },
  waitlisted: {
    top: 25,
    right: -80,
    width: 250,
    overflow: 'hidden',
    fontWeight: 'bold',
    lineHeight: '30px',
    textAlign: 'center',
    position: 'absolute',
    transform: 'rotate(40deg)',
    boxShadow: theme.shadows[3],
    color: theme.palette.common.white,
    backgroundColor: '#6868c9',
  },
  progress: {
    marginLeft: 2,
    marginRight: 2,
  },
  itemContainer: { display: 'block' },
  itemOther: { backgroundColor: theme.palette.grey[50] },
  itemCanceled: { backgroundColor: theme.palette.grey[200] },
});

const LIMIT = 6;
const LIMIT_LAST = 4;

const BookingHistory = (props) => {
  const history = useHistory();
  const { classes, golf } = props;
  const [last, setLast] = useState([]);
  const [comingSoon, setComingSoon] = useState([]);
  const [pageNext, setPageNext] = useState(0);
  const [totalNextCount, setTotalNextCount] = useState(0);
  const [pageLast, setPageLast] = useState(0);
  const [totalLastCount, setTotalLastCount] = useState(0);

  const {
    isLoading: loadingNext,
    isFetching: isFetchingNext,
    error: errorNext,
  } = useQuery(
    ['academy-my-next-lesson-resistrations', pageNext],
    async () => {
      const data = await Academy.api.getMyLessonRegistrations(masterClient, {
        filter: {
          lessonEndDate: {
            gte: moment().format('YYYY-MM-DDTHH:mm:ss'),
          },
        },
        sort: {
          lessonStartDate: 'ASC',
        },
        limit: LIMIT,
        offset: pageNext * LIMIT,
      });
      const allData = data.items.map((res) => ({
        ...res,
        lesson: {
          ...res.lesson,
          name: res.lesson.type === 'GROUP' ? res.lesson.name : `Cours avec ${res.lesson.headCoach.lastName} ${res.lesson.headCoach.firstName}`,
        },
        golf,
      }));
      setTotalNextCount(data.totalCount);
      setComingSoon([...comingSoon, ...allData]);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      retry: 0,
      cacheTime: 0,
      initialData: () => [],
    },
  );
  const {
    isLoading: loadingLast,
    isFetching: isFetchingLast,
    error: errorLast,
  } = useQuery(
    ['academy-my-last-lesson-resistrations', pageLast],
    async () => {
      const data = await Academy.api.getMyLessonRegistrations(masterClient, {
        filter: {
          lessonEndDate: {
            lte: moment().format('YYYY-MM-DDTHH:mm:ss'),
          },
        },
        sort: {
          lessonStartDate: 'DESC',
        },
        limit: LIMIT_LAST,
        offset: pageLast * LIMIT_LAST,
      });
      console.log('last', data);
      const allData = data.items.map((res) => ({
        ...res,
        lesson: {
          ...res.lesson,
          name: res.lesson.type === 'GROUP' ? res.lesson.name : `Cours avec ${res.lesson.headCoach.lastName} ${res.lesson.headCoach.firstName}`,
        },
        golf,
      }));
      setTotalLastCount(data.totalCount);
      setLast([...last, ...allData]);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      retry: 0,
      cacheTime: 0,
      initialData: () => [],
    },
  );

  const loading = loadingLast || loadingNext;
  const error = errorLast || errorNext;
  /* */
  const goToView = (item) => () => {
    history.push(`/myacademy/${item.lessonId || 'details'}`);
  };

  /* */
  const renderItem = (item, key) => {
    const isStarted = moment(item.lesson.startsAt).isBefore()
      && moment(item.lesson.endsAt).isAfter();
    const isCanceled = item.status === 'CANCELED' || item.lesson.status === 'CANCELED';
    const isWaitlisted = item.status === 'WAITLISTED';
    const startLesson = moment(`${item.lesson.startsAt}`, 'YYYY-MM-DD HHmm');
    return (
      <Grid
        item
        key={key}
        md={6} sm={10} xs={12}>
        <ButtonBase
          disableRipple
          component="div"
          onClick={goToView(item)}
          className={classes.itemContainer}>
          <Paper elevation={2}
            className={classNames(classes.item, {
              [classes.itemCanceled]: isCanceled,
            })}>
            <Box
              display="flex"
              flexDirection="row">
              <CalendarDay
                datetime={startLesson}
                endTime={moment(item.lesson.endsAt)}
                displayTime={item.lesson.slotCount <= 1} />
              <Box ml={3} flex={1}>
                <Typography
                  variant="subtitle1"
                  component="span">
                  {item.lesson.name}
                </Typography>
                {item.lesson.slotCount > 1 && (
                  <Chip
                    icon={<MdOutlineBook size={20} />}
                    className={classes.slots}
                    color="default"
                    size="small"
                    label={`${item.lesson.slotCount} créneaux`} />
                )}
              </Box>
            </Box>
            {isCanceled && (
              <Typography
                component="span"
                variant='caption'
                className={classes.canceled}>
                {'Annulée'}
              </Typography>
            )}
            {isWaitlisted && (
              <Typography
                component="span"
                variant='caption'
                className={classes.waitlisted}>
                {'Liste d\'attente'}
              </Typography>
            )}
            {isStarted && (
              <Typography
                component="span"
                variant='caption'
                className={classes.inProgress}>
                {'En cours...'}
              </Typography>
            )}
          </Paper>
        </ButtonBase>
      </Grid>
    );
  };

  /* */
  const renderEmpty = () => (
      <Box textAlign="center">
        <Typography
          paragraph
          variant="h5"
          align="center"
          component="h2">
          {'Aucun cours pour le moment.'}
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => history.push('/academy')}>
          {'S\'inscrire à un cours'}
        </Button>
      </Box>
  );

  /* */
  const renderHistory = () => {
    if ((last.length + comingSoon.length) === 0) {
      return renderEmpty();
    }

    return (
      <React.Fragment>
        {comingSoon.length > 0 && (
          <Box mb={4}>
            <Typography
              paragraph
              variant="h6"
              component="h2">
              {`Mes prochains cours (${totalNextCount})`}
            </Typography>
            <Grid
              container
              spacing={16}>
              {comingSoon.map(renderItem)}
            </Grid>
          </Box>
        )}
        {totalNextCount !== comingSoon.length && (
          <Button
            disabled={isFetchingNext}
            fullWidth
            color="secondary"
            onClick={() => setPageNext(pageNext + 1)}>
            {isFetchingNext ? (
              <CircularProgress color="secondary" size={26} className={classes.progress} />
            ) : (
              'Voir plus'
            )}
          </Button>
        )}
        {last.length > 0 && (
          <Box mb={2}>
            <Typography
              paragraph
              variant="h6"
              component="h2">
              {`Mes cours passées (${totalLastCount})`}
            </Typography>
            <Grid
              container
              spacing={16}>
              {last.map(renderItem)}
            </Grid>
          </Box>
        )}
        {totalLastCount !== last.length && (
          <Button
            disabled={isFetchingLast}
            fullWidth
            color="secondary"
            onClick={() => setPageLast(pageLast + 1)}>
            {isFetchingLast ? (
              <CircularProgress color="secondary" size={26} className={classes.progress} />
            ) : (
              'Voir plus'
            )}
          </Button>
        )}
      </React.Fragment>
    );
  };

  return (
    <Screen
      error={error}
      loading={loading}
      helpURL={`${HELP_URL}/mon-historique/mes-réservations`}
      title="Mes cours">
      {!loading && renderHistory()}
    </Screen>
  );
};

const mapStateToProps = ({ app }) => ({ golf: app.golf });

const StyledComponent = withStyles(styles)(BookingHistory);

export default connect(
  mapStateToProps,
)(StyledComponent);
