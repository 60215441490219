import "../index.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import { unstable_Box as Box } from "@material-ui/core/Box";
import {
  Avatar,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  IconButton,
  Tooltip,
  Divider,
  Menu,
} from "@material-ui/core";
import MomentUtils from '@date-io/moment';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "@material-ui/icons";
import { v4 as uuid } from "uuid";
import { MdOutlineFilterAlt } from "react-icons/md";
import masterClient from "_utils/masterClient";
import { withStyles } from "@material-ui/core/styles";
import { Academy, App, Event, Directory } from "@aps-management/primapp-common";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import {
  KeyboardArrowLeft as LeftIcon,
  KeyboardArrowRight as RightIcon,
} from '@material-ui/icons';
import { BasePicker, Calendar, MuiPickersUtilsProvider } from "material-ui-pickers";

/* */
// eslint-disable-next-line no-unused-vars
const timeConvert = (n) => {
  const num = n;
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return rhours !== 0
    ? `${rhours}h${rminutes !== 0 ? `${String(`0${rminutes}`).slice(-2)}` : ""}`
    : `${String(`0${rminutes}`).slice(-2)}min`;
};

/* */
const styles = (theme) => {
  return {
    loadingContainer: {
      display: "flex",
      flex: 1,
      paddingTop: 20,
      justifyContent: "center",
    },
    loaderContainer: {
      flex: 1,
      flexGrow: 1,
      display: "flex",
      justifyContent: "center",
      padding: `${theme.spacing.unit * 4}px 0`,
    },
    radioGroup: {
      position: "relative",
      border: 0,
      borderRadius: 3,
      height: 48,
      padding: "0 30px 0 0",
      marginBottom: "20px",
    },
    radioButton: {
      margin: 0,
      fontSize: 12,
      height: 36,
      minWidth: 64,
      padding: 4,
      color: theme.palette.common.white,
    },
    radioButtonActive: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    radioButtonInactive: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.common.white,
      },
    },
    radioButtonRoot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: 4,
      borderRadius: 5,
      border: `1px solid ${theme.palette.divider}`,
      userSelect: "none",
    },
    radioButtonLabelActive: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: 600,
      color: theme.palette.common.white,
    },
    radioButtonLabelInactive: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: 600,
      color: theme.palette.secondary.main,
    },
    dateContainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-evenly",
      paddingBottom: 4,
    },
    timeContainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-evenly",
    },
    filterContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      flex: 1,
      padding: 4,
    },
    filterInterContainer: {
      display: "flex",
      backgroundColor: "white",
      flex: 1,
      padding: 2,
      marginBottom: 15,
      borderRadius: 5,
      boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.1)",
    },
    filterInterIconContainer: {
      display: "flex",
      width: "28px",
      marginRight: "16px",
      marginLeft: "16px",
      alignItems: "center",
      justifyContent: "center",
    },
    filterInterContentContainer: {
      display: "flex",
      flexWrap: "wrap",
      backgroundColor: "white",
      padding: 8,
    },
    filterInterContentContainerItemActive: {
      display: "flex",
      padding: 2,
      borderRadius: 5,
      border: `1px solid ${theme.palette.divider}`,
      alignItems: "center",
      justifyContent: "flex-start",
      cursor: "pointer",
      marginRight: 4,
      backgroundColor: theme.palette.common.white,
    },
    filterInterContentContainerItemInactive: {
      display: "flex",
      padding: 2,
      borderRadius: 5,
      border: `1px solid ${theme.palette.divider}`,
      alignItems: "center",
      justifyContent: "flex-start",
      cursor: "pointer",
      marginRight: 4,
      backgroundColor: "gray",
    },
    filterInterContentContainerItemAvatar: {
      textShadow: `
        1px 1px 0 #00000033,
        -1px 1px 0 #00000033,
        -1px -1px 0 #00000033,
        1px -1px 0 #00000033
          `,
      fontWeight: 800,
      fontSize: 12,
      borderRadius: 5,
      width: 32,
      height: 32,
    },
    lessonsContainer: {
      overflowY: "auto",
      overflowX: "hidden",
      maxHeight: "calc(100vh - 445px)",
    },
    loadingContainerTime: {
      fontWeight: "bold",
      fontSize: 12,
      paddingRight: 4,
      width: 65,
      userSelect: "none",
    },
    loadingContainerSlotItem: {
      margin: 4,
      padding: 4,
      display: "flex",
      width: 'calc((100% - 40px) / 4)',
      backgroundColor: theme.palette.common.white,
      borderRadius: 5,
      border: `1px solid ${theme.palette.divider}`,
      alignItems: "center",
      justifyContent: "flex-start",
      cursor: "pointer",
    },
    loadingContainerSlotItemAvatar: {
      color: "white",
      textShadow: `
      1px 1px 0 #00000033,
      -1px 1px 0 #00000033,
      -1px -1px 0 #00000033,
      1px -1px 0 #00000033
    `,
      fontWeight: 800,
      fontSize: 12,
      borderRadius: 5,
      width: 32,
      height: 32,
    },
    loadingContainerSlotItemAvatarLabel: {
      fontWeight: 700,
      fontSize: "0.8rem",
      borderRadius: 5,
      padding: 4,
      marginLeft: 4,
      marginRight: 0,
      userSelect: "none",
    },
    radioButtonContent: {
      display: "none",
    },
    price: {
      position: "absolute",
      bottom: -10,
      height: 25,
      fontWeight: "bold",
      borderRadius: "5px",
      backgroundColor: "rgba(224, 225, 215, 1)",
      cursor: "pointer",
    },
  };
};

const SkeletonLoader = ({ classes }) => {
  return (
    <svg width="100%" height="50" xmlns="http://www.w3.org/2000/svg">
      <rect
        id="pulseBar"
        fill="rgb(170,170,170)"
        width="100%"
        height="100%"
        rx="5"
        ry="5"
      />
    </svg>
  );
};
const IndividualLesson = ({
  golf = { id: 1, options: { academy: {} } },
  account,
  classes,
  ...props
}) => {
  const history = useHistory();
  const {
    id: golfId,
    options: { academy },
  } = golf;
  const { durations } = academy.booking;
  const [durationTime, setDurationTime] = useState(durations[0].minutes);
  const [coachs, setCoachs] = useState([]);
  const [availabilities, setAvailabilities] = useState([]);
  const [nextRefetch, setNextRefetch] = useState(moment());
  const [breakTime] = useState(0);
  const [selectDateTime, setSelectDateTime] = useState(moment());
  const [coachsFilters, setCoachsFilters] = useState(null);
  const [datePickerAnchor, setDatePickerAnchor] = useState(false);

  const handleOpenDatePicker = (e) => {
    setDatePickerAnchor(e.currentTarget);
  };

  const handleCloseDatePicker = () => {
    setDatePickerAnchor(null);
  };

  const { isFetching: coachsLoading } = useQuery(
    ["academy-coachs"],
    async () => {
      const coachsData = await Academy.api.getCoachs(masterClient);
      if (!coachsFilters) {
        setCoachsFilters(coachsData.map(({ id }) => id));
      }
      setCoachs(coachsData);
      return coachsData;
    },
    {
      enabled: !!golfId,
      retry: 0,
      cacheTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  const { isFetching: loadingOptions, error: availabilitiesError, data: availabilitiesData } = useQuery(
    [
      "availabilities",
      durationTime + breakTime,
      moment(selectDateTime).format("YYYY-MM-DD"),
      moment(nextRefetch).format("YYYY-MM-DDTHH:mm:ss"),
    ],
    () =>
      Academy.api.getCoachAvailabilityTimes(masterClient, {
        duration: durationTime + breakTime,
        date: moment(selectDateTime).format("YYYY-MM-DD"),
        coachIds: coachs.map(({ id }) => id),
      }),
    {
      enabled: coachs.length > 0,
      initialData: () => [],
      retry: 0,
      cacheTime: 0,
      refetchInterval: moment(nextRefetch).diff(moment(), "seconds") * 1000,
      refetchIntervalInBackground: true,
    }
  );

  useEffect(() => {
    const reorganizeAvailabilities = (data) => {
      const reorganizedAvailabilities = data
        .filter((a) => a.coach && coachsFilters.includes(a.coach.id))
        .reduce((acc, availability) => {
          availability.availableTimes.forEach((time) => {
            acc[time] = acc[time] || {
              time,
              coachIds: [],
            };
            acc[time].coachIds.push(availability.coach.id);
          });
          return acc;
        }, []);
      return Object.keys(reorganizedAvailabilities)
        .sort((a, b) => moment(a, "HH:mm").diff(moment(b, "HH:mm"), "minutes"))
        .map((time) => {
          return {
            time: moment(time, "HH:mm").format("HH:mm"),
            coachIds: reorganizedAvailabilities[time].coachIds,
          };
        })
        .filter((a) => a);
    };
    if (availabilitiesData && availabilitiesData.length > 0) {
      const availabs = reorganizeAvailabilities(
        availabilitiesData?.filter((item) =>
          coachsFilters.includes(item?.coach?.id)
        )
      );
      setAvailabilities(availabs);
      if (availabs[0]) {
        const next = moment(
          `${moment(selectDateTime).format("YYYY-MM-DD")}T${availabs[0].time}:00`
        );
        setNextRefetch(next);
      }
    }
  }, [availabilitiesData, coachsFilters, selectDateTime]);

  const handleBook = (data) => {
    props.initialize({ lesson: data, status: ["init"] });
    history.push(`/academy/${data.id}/individual`);
  };

  const loading = coachsLoading || loadingOptions;
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
    <Box>
      <Box className={classes.dateContainer}>
        <IconButton
          onClick={() =>
            setSelectDateTime(selectDateTime.clone().subtract(1, "day"))
          }
          color="primary"
        >
          <ChevronLeftIcon />
        </IconButton>
        <Button
          aria-owns={datePickerAnchor ? 'simple-menu' : undefined}
          aria-haspopup="true"
          style={{ textTransform: "none", fontWeight: 500, fontSize: 18 }}
          onClick={handleOpenDatePicker}
        >
          {selectDateTime?.format("dddd DD MMMM YYYY").toUpperCase()}
        </Button>
        <IconButton
          onClick={() =>
            setSelectDateTime(selectDateTime.clone().add(1, "day"))
          }
          color="primary"
        >
          <ChevronRightIcon />
        </IconButton>
      </Box>
        <Menu
          id="lock-menu"
          anchorEl={() => datePickerAnchor}
          open={Boolean(datePickerAnchor)}
          onClose={handleCloseDatePicker}
        >
          <BasePicker
            onChange={(e) => setDatePickerAnchor(null)}
            value={selectDateTime.toDate()}>
            {({ date }) => (
              <Calendar
                date={date}
                disablePast={true}
                leftArrowIcon={<LeftIcon />}
                rightArrowIcon={<RightIcon />}
                onChange={(e) => {
                  setSelectDateTime(e);
                  setDatePickerAnchor(null);
                }}
              />
            )}
          </BasePicker>
        </Menu>
      <Box className={classes.timeContainer}>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={"value"}
          className={classes.radioGroup}
        >
          {durations?.map((d, i) => (
            <FormControlLabel
              key={`duration-${d.minutes}-${i}`}
              value={String(durationTime)}
              label={timeConvert(d.minutes)}
              className={classNames(classes.radioButton, {
                [classes.radioButtonActive]: durationTime === d.minutes,
                [classes.radioButtonInactive]: durationTime !== d.minutes,
              })}
              classes={{
                root: classes.radioButtonRoot,
                label: classNames(classes.radioButton, {
                  [classes.radioButtonLabelActive]: durationTime === d.minutes,
                  [classes.radioButtonLabelInactive]:
                    durationTime !== d.minutes,
                }),
              }}
              control={
                <Radio
                  className={classes.radioButtonContent}
                  onClick={() => setDurationTime(d.minutes)}
                  variant={"contained"}
                  color={"default"}
                >
                  {timeConvert(d.minutes)}
                </Radio>
              }
            />
          ))}
        </RadioGroup>
      </Box>
      <Box className={classes.filterContainer}>
        <Box className={classes.filterInterContainer}>
          <Box className={classes.filterInterIconContainer}>
            <MdOutlineFilterAlt size="1.5rem" />
          </Box>
          <Box className={classes.filterInterContentContainer}>
            {coachs.map((coach, i) => {
              const isInFilters = coachsFilters?.includes(coach.id);
              return (
                <Box
                  key={`${coach.id}-${i}`}
                  className={
                    isInFilters
                      ? classes.filterInterContentContainerItemActive
                      : classes.filterInterContentContainerItemInactive
                  }
                  onClick={(e) => {
                    return isInFilters
                      ? setCoachsFilters(
                          coachsFilters.filter((c) => c !== coach.id)
                        )
                      : setCoachsFilters([...coachsFilters, coach.id]);
                  }}
                >
                  <Tooltip title={`${coach.lastName} ${coach.firstName}`}>
                    <Avatar
                      variant="square"
                      className={classes.filterInterContentContainerItemAvatar}
                      style={{
                        backgroundColor: isInFilters ? coach.color : "gray",
                        border: `2px solid ${
                          isInFilters ? coach.color : "gray"
                        }`,
                        color: isInFilters ? "white" : "gray",
                      }}
                    >{`${coach.lastName.charAt(0)}${coach.firstName.charAt(
                      0
                    )}`}</Avatar>
                  </Tooltip>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box className={classes.lessonsContainer}>
        {!availabilitiesError && !loading
          ? coachsFilters &&
            coachsFilters.length > 0 && availabilities.length > 0 ?
            availabilities.map((slot, index) => (
              <Box key={index} display="flex" flexDirection="column">
                <Divider />
                <Box padding={1} display="flex" alignItems="center">
                  <Typography className={classes.loadingContainerTime}>
                    {slot.time}
                  </Typography>
                  <Box
                    key={`${slot.time}-${index}`}
                    display="flex"
                    flexWrap="wrap"
                    width="100%"
                  >
                    {slot.coachIds.map((coachId, i) => {
                      const coach = coachs.find((c) => c.id === coachId);
                      return (
                        <Tooltip
                          title={`${coach.lastName} ${coach.firstName}`}
                        >
                        <Button
                          key={`${coachId}-${i}`}
                          className={classes.loadingContainerSlotItem}
                          onClick={() =>
                            handleBook({
                              id: uuid(),
                              type: "INDIVIDUAL",
                              headCoach: coach,
                              name: "Cours individuel",
                              access: "PUBLIC",
                              status: "ACTIVE",
                              startsAt: moment(
                                `${selectDateTime.format("YYYY-MM-DD")}T${
                                  slot.time
                                }:00`
                              ).format("YYYY-MM-DDTHH:mm:ss"),
                              endsAt: moment(
                                `${selectDateTime.format("YYYY-MM-DD")}T${
                                  slot.time
                                }:00`
                              )
                                .add(durationTime, "minutes")
                                .format("YYYY-MM-DDTHH:mm:ss"),
                              price: availabilitiesData[0].price,
                              slotCount: 1,
                              attendeeCount: 1,
                              coachSlots: [
                                {
                                  coachId,
                                  coach,
                                  startsAt: moment(
                                    `${selectDateTime.format("YYYY-MM-DD")}T${
                                      slot.time
                                    }:00`
                                  ).format("YYYY-MM-DDTHH:mm:ss"),
                                  endsAt: moment(
                                    `${selectDateTime.format("YYYY-MM-DD")}T${
                                      slot.time
                                    }:00`
                                  )
                                    .add(durationTime, "minutes")
                                    .format("YYYY-MM-DDTHH:mm:ss"),
                                  duration: `${durationTime}:00`,
                                  endsAtIncludingBreak: moment(
                                    `${selectDateTime.format("YYYY-MM-DD")}T${
                                      slot.time
                                    }:00`
                                  )
                                    .add(durationTime + breakTime, "minutes")
                                    .format("YYYY-MM-DDTHH:mm:ss"),
                                  status: "ACTIVE",
                                },
                              ],
                              createdAt: moment().format("YYYY-MM-DDTHH:mm:ss"),
                              cancellation: null,
                              product: null,
                            })
                          }
                        >
                            <Avatar
                              variant="square"
                              className={classes.loadingContainerSlotItemAvatar}
                              style={{
                                backgroundColor: coach.color,
                                border: `2px solid ${coach.color}`,
                              }}
                            >{`${coach.lastName.charAt(
                              0
                            )}${coach.firstName.charAt(0)}`}</Avatar>
                          <Typography
                          noWrap  
                            className={
                              classes.loadingContainerSlotItemAvatarLabel
                            }
                          >
                            {coach.lastName} {coach.firstName}
                          </Typography>
                        </Button>
                        </Tooltip>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            )) : (
              <div className={classes.loaderContainer}>
                <Typography variant="h5">{'Aucun créneau disponible'}</Typography>
              </div>
            )
          : !availabilitiesError ? Array(10)
              .fill(0)
              .map((_, i) => (
                <Box
                  key={`skeleton-${i}`}
                  display="flex"
                  flexDirection="column"
                >
                  <Divider />
                  <Box padding={1} display="flex" alignItems="center">
                    <SkeletonLoader />
                  </Box>
                </Box>
              )) : (
                <div className={classes.loaderContainer}>
                  <Typography variant="h5">{'Aucun créneau disponible'}</Typography>
                </div>
              )}
      </Box>
    </Box>
    </MuiPickersUtilsProvider>
  );
};
/* */
const mapStateToProps = ({ app: { golf, account, user }, eventEnter }) => ({
  golf,
  user,
  account,
  enter: eventEnter,
});

const mapDispatchToProps = {
  ...App.actions,
  ...Event.actions,
  ...Directory.actions,
};

const styledComponent = withStyles(styles)(IndividualLesson);

export default connect(mapStateToProps, mapDispatchToProps)(styledComponent);

