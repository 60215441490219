import React from 'react';

/* */
class TermsAndConditions extends React.Component {
  /* */
  constructor(props) {
    super(props);
    document.title = "Conditions générales d'utilisation";
  }

  /* */
  render() {
    return (
      <div className="page">
        <h1>Conditions générales d'utilisation</h1>
        <h2>I. Introduction et consentement</h2>
        <p>Bienvenue sur l’application Prima.Golf et sur le site internet www.prima.golf (l'application et le site internet seront nommés le "Site"). Lorsque vous utilisez le site, vous acceptez, sans réserve ni restriction, de respecter les présentes conditions (‘’Conditions’’). Si vous n’acceptez pas les présentes conditions, veuillez ne pas utiliser le présent Site.</p>
        <p>Veuillez noter que le Site peut contenir des liens vers d’autres sites web, opérés par de tierces parties, et sont sujets à leurs propres termes et conditions.</p>
        <h2>II. Confidentialité</h2>
        <p>Pour connaître la politique de Prima.Golf en matière de collecte, d’utilisation et de communication de renseignements sur les utilisateurs, veuillez consulter notre politique de vie privée.</p>
        <h2>III. Inscription</h2>
        <p>L’utilisation de certains services et fonctions offertes sur le Site, exige une inscription. Si vous décidez de vous inscrire ou de vous abonner à ces services ou fonctions, vous acceptez de fournir des renseignements exacts et à jour à votre sujet comme l’exige le processus d’inscription ou d’abonnement pertinent, et de vous assurer de leur exactitude en effectuant les mises à jour nécessaires dès que possible. Vous acceptez :</p>
        <ul>
        <li>d’assurer la confidentialité de tous les mots de passe ou autres éléments d’identification de compte que vous aurez choisis ou qui vous seront attribués au moment d’une inscription ou d’un abonnement</li>
        <li>d’assumer la responsabilité à l’égard de toutes les activités reliées à l’utilisation de ces mots de passe ou de ces comptes. Prima.Golf ne peut aucunement être tenue responsable, directement ou indirectement, des pertes ou dommages de quelque nature que ce soit résultant du défaut de vous conformer à la présente disposition, ou liés à un tel défaut. Si Prima.Golf a des raisons de croire que l’information communiquée dans le cadre de l’inscription n’est pas pertinente, Prima.Golf se réserve le droit de supprimer le compte en question.</li>
        </ul>
        <h2>IV. Transactions</h2>
        <p>Dans le cas où vous utiliserez les différentes fonctionnalités du Site (réservation de départs, abonnement, inscription à une compétition, …) veuillez vous référer à la politique d’utilisation et à la politique d’annulation.</p>
        <h2>V. Sécurité de votre compte</h2>
        <p>Vous êtes entièrement responsable du maintien de la confidentialité de votre mot de passe. De plus, vous acceptez de prévenir Prima.Golf de toute utilisation non autorisée de votre mot de passe ou de votre compte en le communiquant à l’adresse tech@primagolf.fr. Afin de supprimer votre compte, vous pouvez envoyer un courriel à tech@primagolf.fr. Précisez dans votre courriel la nature de votre demande. Prima.Golf se réserve le droit de divulguer l’information de votre compte, incluant l’information reliée à votre carte de crédit, dans des cas qui relèvent de la loi et la sécurité du Site et de ses utilisateurs. Ces cas inclus toutes questions de fraude et réduction de risque de crédit.</p>
        <h2>VI. Eligibilité</h2>
        <p>Ce Site est pour les utilisateurs de 16 ans et plus. Toute inscription ou utilisation du site par un mineur de moins de 16 ans n’est pas autorisée et en violation des présents termes et conditions. En utilisant ce Site, vous reconnaissez être âgé de 16 ans et plus et vous vous engagez à respecter les termes et conditions de cette politique.</p>
        <h2>VII. Conduite des utilisateurs</h2>
        <p>Quand vous utilisez ce Site, vous acceptez de respecter toutes les lois applicables ainsi que les présentes conditions. Vous reconnaissez que Prima.Golf peut enquêter sur toute violation de la loi et collaborer avec les autorités chargées de l’application des lois dans le cadre de poursuite contre un utilisateur. De plus, vous vous engagez à ne pas faire ce qui suit :</p>
        <p>Afficher, transmettre ou distribuer tout renseignement, matériel ou contenu (y compris un logiciel) renfermant un virus, un robot d’annulation de messages, un cheval de Troie, un ver ou tout autre élément nocif ou perturbateur, ou établir un lien vers ce type de renseignement, matériel ou contenu; </p>
        <p>Afficher, transmettre ou distribuer des renseignements ou du contenu, ou établir un lien vers du matériel, des renseignements ou du contenu qui visent, préconisent ou encourageant une conduite qui pourrait constituer une infraction criminelle ou engager une responsabilité civile, ou utiliser autrement un site ou un service de Prima.Golf de façon illégale ou dans le but de restreindre ou d’entraver l’utilisation par une autre personne de ce Site; </p>
        <p>Créer ou utiliser une fausse identité; </p>
        <p>Ajouter des liens profonds à toute portion du présent Site, quel qu’en soit le but, sans notre autorisation expresse écrite; </p>
        <p>Bloquer toute partie du site Web, en créer une image miroir ou l’incorporer d’une autre façon à un autre site Web sans notre autorisation écrite préalable;</p>
        <p>Essayez d’avoir accès aux parties du site qui ne sont pas accessibles au grand public.</p>
        <h2>VIII. Contenu lié au site</h2>
        <p>Veuillez noter que le Site peut contenir des liens vers d’autres sites Web. Prima.Golf fournit ces liens pour votre commodité uniquement et leur inclusion ne constitue pas une recommandation de Prima.Golf. Prima.Golf n’exerce pas de contrôle sur les sites liés. Quand vous visitez un site Web lié, vous le faites à vos risques et il vous appartient de prendre toutes les mesures de protection appropriées contre les virus et autres éléments destructeurs. Prima.Golf n’assume aucune responsabilité à l’égard du contenu de ces sites liés ou de toute autre page Web ne faisant pas partie du Site et sur laquelle elle n’exerce aucun contrôle. Sauf indication contraire énoncée expressément, Prima.Golf ne faut aucune représentation ni recommandation et n’offre aucune garantie à l’égard des sites liés ni des renseignements, produits ou services qui y figurent. En conséquence, vous reconnaissez que Prima.Golf n’assume aucune responsabilité pour ce qui est de l’exactitude, de la pertinence, de la conformité au droit d’auteur, de la légalité ou de la convenance des éléments qui figurent sur les sites auxquels renvoie le Site Prima.Golf.</p>
        <h2>IX. Services mobiles</h2>
        <p>Le Site est également accessible à partir d’un appareil mobile. Il n’y a aucun frais d’utilisation pour ce service mais les frais normaux demandés par votre opérateur mobile s’appliquent. Cette politique s’applique également à l’accès du Site à partir d’un appareil mobile. Tout ce que vous remplissez sur le Site à partir d’un appareil mobile sera également stocké sur les serveurs de Prima.Golf.</p>
        <h2>X. Propriété intellectuelle</h2>
        <p>La présente disposition s’applique à tout matériel, renseignement et contenu (le ‘’Contenu’’) offert sur le Site Prima.Golf, y compris tout logiciel, design, images, texte, data, photos, illustrations, clips audio, clips vidéo, graphisme, logos, icônes et marques de commerce. Le Contenu est protégé par les lois et les traités français sur le droit d’auteur. Prima.Golf vous concède une licence limitée, non exclusive et non transférable qui vous permet d’utiliser et d’afficher sur votre ordinateur ou un autre dispositif d’accès électronique, le contenu et les services pour votre usage personnel uniquement et non à des fins commerciales, pourvu que vous acceptiez de ne pas le modifier et de conserver tous les avis relatifs aux droits d’auteur et aux droits de propriété. Sauf indication contraire dans les présentes, vous vous engagez à ne pas reproduire, retransmettre, distribuer, vendre, publier, communiquer, diffuser ou autrement rendre accessible aucune partie du contenu obtenue par le biais de ce Site, ni d’en faire des œuvres dérivées, notamment par la mise en cache, la mise en forme ou un autre moyen semblable, sans avoir préalablement obtenu le consentement écrit du droit d’auteur du contenu en question.</p>
        <h2>XII. Déni de responsabilité</h2>
        <p>Vous comprenez et acceptez expressément ce qui suit :</p>
        <p>Le site et les services de Prima.Golf sont offerts ‘’tels quels’’ et en fonction de leur disponibilité, et Prima.Golf ne donne aucune garantie et ne fait aucune déclaration d’aucune sorte, expresse ou implicite, notamment de garantie implicite de qualité marchande, d’adaptation à un usage particulier et d’absence de contrefaçon, et Prima.Golf rejette expressément de telles garanties</p>
        <p>Prima.Golf ne donne aucune garantie que le site et les services de Prima.Golf, y compris le contenu, les renseignements, seront accessibles sans interruption, en temps utile, en toute sécurité et sans erreur, ni que de tels service ou les résultats obtenus par suite de leur utilisation répondront à vos exigences ou vos attentes.</p>
        <p>Les renseignement, logiciels et services publiés sur le présent site peuvent contenir des inexactitudes ou des erreurs y compris des erreurs de prix. Prima.Golf n’assume aucune responsabilité en cas d’erreur ou d’inexactitude relation à l’information concernant un golf ou la description de celui ci (notamment les prix, photographies, commodités offertes par le club de golf et les descriptions générales). de plus, Prima.Golf se réserve expressément le droit de corriger toute erreur de prix sur notre site web. Dans un tel cas, nous vous offrirons le choix de maintenir votre achat  en cours au prix corrigé ou, si vous le souhaitez, nous annulerons votre achat sans pénalité.</p>
        <p>Prima.Golf ne pourra être tenu responsable envers les utilisateurs du site et de ses services pour toute perte ou dommage indirect, accessoire ou spécial, toute perte de profit, perte d’occasion ou de revenu ou autre réclamations de rémunération quelconques découlant directement ou indirectement des présentes conditions générales ou en rapport avec celles-ci, incluant tout achat ou confirmation d’achat, incluant toute omission ou tout retard à remplir toute obligation des présentes. La responsabilité de Prima.Golf relativement à toutes réclamations découlant de l’exécution ou de la non-exécution des obligations reliés à tout service provenant du site ne saura excéder le prix d’achat payé à Prima.golf. un tel montant s’avérera le seul recours de l’acheteur ainsi que la seule responsabilité de Prima.Golf à ces égards.</p>
        <p>Prima.Golf ne pourra être tenu responsable pour la non-exécution des obligations reliées à tout service provenant du Site si cette non-exécution est causé par un événement hors de son contrôle, incluant mais n’étant pas limité à, une panne Internet, une panne des réseaux de communication, un feu, une inondation ou une guerre.</p>
        <h2>Contactez Prima.Golf</h2>
        <p>N’hésitez pas à partager tout commentaire ou inquiétude. Si vous avez des questions au sujet de cette politique sur la vie privée, contactez :</p>
        <p><strong>Prima.Golf</strong><br/>16 Place du Martroi<br/>45000 Orléans</p>
        <p>Ou par courriel : tech@primagolf.fr</p>
        <br/><br/>
      </div>
    );
  }
}

export default TermsAndConditions;
